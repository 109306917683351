.progressBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
}

.bar {
    width: 100%;
    background-color: rgba(133, 227, 255, 0.2);
    box-shadow:
        0 1px 0 0 #435257,
        0 1px 0 0 #000 inset;
    position: relative;
}

.bar__inner {
    height: 100%;
    background-color: #85e3ff;
}

.mark {
    --mark-color: black;

    position: absolute;
    top: 0;
    height: 100%;
    padding-left: 3px;
}

.mark::before {
    content: "";
    width: 1.3px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--mark-color);
}

.downloadAppBanner__container {
    --download-app-banner-height: 80px;

    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    overflow: hidden;
    height: var(--download-app-banner-height);
    height: calc(
        var(--download-app-banner-height) + var(--safe-area-inset-bottom)
    );
    padding-bottom: var(--safe-area-inset-bottom);
    font-family: var(--font-family-title);
    text-transform: uppercase;
    background: linear-gradient(157deg, #243137 0.27%, #1a2328 96.86%);
    border-top: 2px solid #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.downloadAppBanner__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    font-size: 25px;
    margin-top: 5px;
}

.downloadAppBanner__subtitle {
    color: #6cf011;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
    font-size: 18px;
}

.round_icon {
    font-size: 26px;
    width: 1em;
    height: 1em;
    background-color: #fff;
    border: 3px solid #3b4a4d;
    border-radius: 50%;
    margin-left: 0.2em;
}

.round_icon__icon {
    transform: rotate(180deg);
    color: #3b4a4d;
    stroke-width: 3px;
    border: 1px solid #000;
    border-radius: 50%;
    display: block;
}

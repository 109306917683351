.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: transparent;
}

.inner {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

:root {
    /*
    On some Android 10 and higher devices, the "force dark" feature (android:forceDarkAllowed)
    is on by default, which causes WebView to change some elements' colors to try and produce a dark
    theme. Setting color-scheme prevents this behavior.
    https://developer.android.com/develop/ui/views/layout/webapps/dark-theme
    https://developer.android.com/develop/ui/views/theming/darktheme#force-dark
    */
    color-scheme: light only;

    /* Fonts */
    --font-family-default: "Roboto", sans-serif;
    --font-family-cursive: "Caveat", cursive;
    --font-family-title: "Oswald", sans-serif;

    /* Layout */
    --container-border-radius: 12px;

    /* Z-Indexes */
    --zindex-splash-screen: 1100;
    --zindex-confetti: 1020;
    --zindex-whisper-effect: 1019;
    --zindex-paste-context-menu: 1018;
    --zindex-alert: 1010;
    --zindex-loading-message: 1000;
    --zindex-modal: 100;
    --zindex-reward: 100;
    --zindex-popover-screen: 95;
    --zindex-shutdown: 90;
    --zindex-broken-screen-effect: 80;
    --zindex-full-screen-animation: 75;
    --zindex-note: 70;
    --zindex-tutorial: 30;
    --zindex-hint-button: 25;
    --zindex-phone-call: 21;
    --zindex-phone-footer: 20;
    --zindex-phone-lock-screen: 19;

    /* Safe areas default values, overridden by JS */
    --safe-area-inset-top: 0px;
    --safe-area-inset-bottom: 0px;
}

* {
    box-sizing: border-box;
}

*:not(input, textarea) {
    user-select: none;
}

*:focus {
    outline: none;
}

a {
    text-decoration: none;
}

html {
    -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: var(--font-family-default);

    /*
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    color: #1e1e1e;

    /*
    On iOS, when you touch and hold a touch target such as a link,
    Safari displays a callout containing information about the link.
    This property disables that callout.
    */
    -webkit-touch-callout: none;
}

code {
    font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
        monospace;
}

textarea {
    resize: none;
}

img {
    -webkit-user-drag: none;
}

/* Fontello icons overrides */
[class^="icon-"]::before,
[class*=" icon-"]::before {
    margin-left: 0;
    margin-right: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.removeMandatoryAdsOnBanner {
    background: #000;
    bottom: 0;
    color: white;
    height: 12px;
    left: 0;
    position: relative;
    right: 0;
    z-index: 9999;
}

.overwrite.closeButton {
    color: white;
    position: absolute;
    top: -5px;
    left: 10px;
    width: 24px;
}

/* 
    Layout containers to help positioning elements inside a 100% width containers.
    Aligning or spreading elements inside this container can be done using
    any 'justify-x' or 'align-x' properties. 
*/

/*  
    Used as a container of elements spread in a column. For example;
        <div className={"flexColumn"}>
            <div className={"flexRow"}> 
                <Button />
                <Button />
                <Button />
            </div>
            <div className={"flexRow"}> 
                <Button />
                <Button />
                <Button />
            </div>
            <div className={"flexRow"}> 
                <Button />
                <Button />
                <Button />
            </div>
        </div    
 */
.flexColumn {
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
}

/* 
    Used as a container of elements spread in a row
*/
.flexRow {
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: row;
}

.centerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.flexCenterRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

:root {
    --color-bg-primary: #5ff39d;
    --color-bg-secondary: #85e3ff;
    --color-bg-premium: #ff7800;
    --color-bg-exclusive: #bb00ff;
    --color-bg-screen: #192021;
    --color-text-primary: #1dbf73;
    --color-text-highlight: #269add;
    --color-text-error: #f4364c;
    --color-text-secondary: #ffe58a;
    --color-text-dark-gray: #1e1e1e;
    --color-stroke-1: #00045a;

    /* Dark-blue theme colors */
    --gradient-green: linear-gradient(180deg, #4dea66, #15a23d);
    --gradient-light-blue: linear-gradient(180deg, #7cedcb 0%, #53c4cb 100%);
    --gradient-gray: linear-gradient(180deg, #aaa, #666);
    --gradient-dark-gray: linear-gradient(180deg, #666, #000);
    --gradient-blue-button: linear-gradient(
            360deg,
            rgba(167, 234, 255, 0) 44.72%,
            rgba(167, 234, 255, 0.1) 100%
        ),
        linear-gradient(
            0deg,
            rgba(167, 234, 255, 0.1),
            rgba(167, 234, 255, 0.1)
        );

    /* v2 theme colors */
    --color-text-link-green-v2: #28b602;
    --color-text-green-v2: #1b3d10;
    --color-input-text-bg: #0f0d14;
    --color-input-text-border: #42555b;
    --gradient-green-v2: linear-gradient(180deg, #93fd45 0%, #34e30c 100%);
    --gradient-lit-green-v2: linear-gradient(180deg, #c0fc43 0%, #87eb1b 100%);
    --color-text-blue-v2: #0e3847;
    --gradient-blue-v2: linear-gradient(180deg, #4bbde2 0%, #3da4c7 100%);
    --color-text-gray-v2: #263940;
    --gradient-gray-v2: linear-gradient(180deg, #5d7883 0%, #475b62 100%);
    --gradient-purple-v2: linear-gradient(180deg, #e861e7 0%, #9e2bab 100%);
    --gradient-orange-v2: linear-gradient(180deg, #f5bc04 0%, #d7870c 100%);
}

.modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--zindex-modal);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contents,
.imageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contents {
    width: 92%;
    max-width: 600px;
    max-height: 90%;
    background-color: white;
    border-radius: var(--container-border-radius);
    padding: 30px;
    text-align: center;
}

.contents.fullWidth {
    display: block;
    text-align: left;
}

.imageContainer {
    margin: auto;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    z-index: 10;
    cursor: pointer;
    color: #333;
    vertical-align: top;
    margin-top: -0.4em;
    margin-right: -0.3em;
}

.title {
    font-size: 17px;
    font-family: var(--font-family-title);
    flex-shrink: 0;
    margin-top: -0.5em;
}

.subtitle {
    font-family: var(--font-family-title);
    flex-shrink: 0;
    font-size: 24px;
}

.title.large {
    font-size: 24px;
}

.text {
    font-size: 17px;
    line-height: 1.2;
}

.text.left {
    width: 100%;
    text-align: left;
}

.textbox {
    width: 100%;
    font-size: 15px;
    line-height: 1.2;
    background-color: #ddd;
    border: none;
    border-radius: 15px;
    margin: 0;
    padding: 15px;
    margin-top: 10px;
    vertical-align: top;
}

.button {
    margin-top: 1em;
    cursor: pointer;
}

.headerElement {
    position: absolute;
    left: 40%;
    top: 0;
    width: 20%;
    transform: translateY(-50%);
}

.headerElement__content {
    width: 100%;
    height: 0;
    padding-top: 100%;
    margin: auto;
}

/* TODO: remove this and consolidate ModalText to a single style */
.theme_darkBlue .title + .text {
    margin-top: 2em;
}

/* blue theme */

.contents.theme_blue {
    background: linear-gradient(168.9deg, #0186bf -3.21%, #5f20ae 97.59%);
    border: 3px solid rgba(255, 255, 255, 1);
}

.theme_blue .title {
    color: #ffffff;
    text-shadow:
        0 2px 0 #4748d8,
        0 4px 6px rgba(0, 0, 0, 0.3);
    padding: 10px 0;
    background: linear-gradient(147.21deg, #9e60ed 10.8%, #a423b9 92.16%);
    margin-bottom: 20px;
}

/* dark theme */

.modal.theme_dark {
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
}

.contents.theme_dark {
    background-color: transparent;
}

.theme_dark .title {
    color: white;
    margin-bottom: 20px;
}

.theme_dark .textbox {
    background-color: #000;
    border: 1px solid #666666;
    color: #fff;
}

.modal ul {
    text-align: left;
    padding: 0 2.5rem 0 4.5rem;
}

.modal li {
    margin-bottom: 1rem;
}

/* fullScreen theme */

.modal.theme_fullScreen {
    background-color: transparent;
    backdrop-filter: none;
}

.contents.theme_fullScreen {
    width: 100%;
    max-height: unset;
    height: 100%;
}

.modal .theme_fullScreen .close {
    color: white;
    right: 20px;
    top: 20px;
}

.modal .theme_fullScreen.contents {
    text-align: unset;
}

.modalItem {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    width: 100%;
    margin-top: 1em;
    font-weight: bold;
}

.modalItem__title {
    white-space: nowrap;
}

.modalItem__children {
    text-align: right;
}

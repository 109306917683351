.splash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--zindex-splash-screen);
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.splash.logoOnly {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: radial-gradient(circle at 75%, #0d0d0f 0%, #202023 40%);
}

.splash__gameLogo {
    /* These must match the props in index.html!! */
    width: 60%;
    max-width: 300px;
}

.splash.logoOnly .loading__spinner {
    margin-top: 40px;
}

.splash.exiting {
    opacity: 0;
    pointer-events: none;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 16%;
    z-index: 100;
}

.title {
    font-family: var(--font-family-title);
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    color: #fff;
}

.status {
    margin-top: 15px;
    text-align: center;
    color: #fff;
    min-height: 45px;
}

.tapToRetry {
    font-size: 18px;
    line-height: 1.3;
    text-align: center;
    animation: status-animation 3s linear infinite;
}

.splash.logoOnly .tapToRetry {
    color: #fff;
    margin-top: 40px;
}

.tapToRetry :global(.icon) {
    margin-right: 0.2em;
}

@keyframes status-animation {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* darkBlue Theme */
.theme_darkBlue .contents {
    color: #fff;
    background: linear-gradient(156.9deg, #243137 0%, #1a2328 100%);
    border: 2px solid #43565c;
    box-shadow: 2.6px 2.6px 3.4px 0 rgba(0, 0, 0, 0.7);
}

/* title is above the container, so the title
 should be in a container with:  
    padding-top: 0;  
    overflow: visible,  */
.theme_darkBlue .title {
    font-family: var(--font-family-title);
    color: #fff;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: 0;
    text-align: center;
    margin-top: -0.5em;
}

.titleInline {
    margin-top: 1em;
}

.theme_darkBlue .title .title-big {
    font-size: 52px;
}

.theme_darkBlue .closeButton {
    color: #fff;
    font-family: var(--font-family-title);
    text-transform: uppercase;
    font-weight: normal;
}

.theme_darkBlue .topBorderOnly {
    border: none;
    border-top: 0.5px solid rgba(157, 243, 255, 0.3);
    box-shadow: none;
}

.theme_darkBlue .textbox {
    color: white;
    background-color: var(--color-input-text-bg);
    border: 2px solid var(--color-input-text-border);
    border-radius: var(--container-border-radius);
}

.theme_darkBlue .textbox::placeholder {
    color: #84a1ac;
    font-size: 22px;
    font-family: var(--font-family-cursive);
}

.wrapper {
    --desktop-width: 360px;
    --desktop-height: 800px;

    width: 100%;
    height: 100%;
    overflow: hidden;
    background: radial-gradient(circle at 75%, #0d0d0f 0%, #202023 40%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper.desktop {
    min-width: var(--desktop-width);
    min-height: var(--desktop-height); /* TODO: 740px */
}

.wrapper.mobile.withActions {
    display: flex;
    flex-direction: column;
}

.content {
    --base-font-size: 16px;

    position: relative;
    background-color: #fff;
    overflow: hidden;
}

.desktop .content {
    width: var(--desktop-width);
    height: var(--desktop-height); /* TODO: 740px */
}

.mobile .content {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 1000px;
}

@media screen and (width >= 400px) {
    .mobile .content {
        --base-font-size: 19px;
    }
}

.actions {
    flex-grow: 0;
    text-align: center;
}

.splash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--zindex-splash-screen);
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.splash.exiting {
    animation: fade-out 0.3s ease-in-out both !important;
    pointer-events: none;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.title {
    font-family: var(--font-family-title);
    text-transform: uppercase;
    position: absolute;
    top: 12%;
    text-align: center;
    font-size: 30px;
    color: #fff;
    z-index: 2;
    animation: fade-in 0.3s ease-in-out both;
}

.container {
    position: absolute;
    bottom: 10%;
    text-align: center;
    padding: 0 40px;
    width: 100%;
    height: auto;
    z-index: 2;
    animation: fade-in 0.3s ease-in-out both;
}

.randomTip,
.errorMessage {
    padding: 0.75em 1em;
    height: 3em;
    color: aliceblue;
    z-index: 2;
    line-height: 1.3;
}

.randomTip {
    animation-name: fade-in-and-out;
    animation-iteration-count: infinite;
}

.errorMessage__retryIcon {
    margin-right: 0.3em;
    vertical-align: middle;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-in-and-out {
    0% {
        opacity: 0;
    }

    10%,
    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.consentScreen {
    --gap: 20px;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--zindex-modal);
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s linear;
    padding-top: 40px; /* Chrome < 79 */
    padding-top: clamp(40px, var(--safe-area-inset-top), 60px);
}

.consentScreen.exiting {
    opacity: 0;
    pointer-events: none;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero {
    position: relative;
    z-index: 2;
    height: 52%;
    width: 100%;
    text-align: center;
    animation: flicker 3s linear infinite;
}

.hero__image {
    height: 100%;
    width: auto;
    display: inline-block;
    vertical-align: bottom;
}

.contents {
    position: relative;
    z-index: 2;
    width: 80%;
    height: 100%;
    text-align: center;
    color: #fff;
    line-height: 1.2;
    animation: fade-in 0.5s 0.1s ease-in-out both;
}

.contents::before {
    content: "";
    width: 80%;
    height: 80%;
    border-radius: 20%;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 40px 40px rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 10%;
    left: 10%;
    z-index: -1;
}

.contents__disclaimer {
    font-size: 20px;
    margin-top: 40px;
}

.contents__text {
    position: absolute;
    bottom: 65px;
    font-size: 14px;
}

.button {
    margin-top: var(--gap);
}

.link {
    color: var(--color-text-primary);
}

.contents .link {
    color: #fff;
    text-decoration: underline;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 0.1;
    }

    4% {
        opacity: 1;
    }

    19% {
        opacity: 1;
    }

    21% {
        opacity: 0.1;
    }

    23% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    83% {
        opacity: 0.4;
    }

    87% {
        opacity: 1;
    }
}

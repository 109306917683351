.button {
    --button-outline-color: #6cf011;
    --button-border-radius: 24px;
    --button-border-radius-inner: var(--button-border-radius);
    --button-inner-border: 0.6px solid black;

    font-family: var(--font-family-title);
    font-size: 17.3px;
    line-height: 1;
    text-align: center;
    border: 2px solid var(--button-outline-color);
    border-radius: var(--button-border-radius);
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    position: relative;
    padding: 0; /* the <button> element has default padding */
    flex-shrink: 0;
    flex-grow: 0;
    opacity: 1;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 1;
}

.content {
    border: var(--button-inner-border);
    padding: 0.43em 2.2em;
    border-radius: var(--button-border-radius-inner);
}

.button.theme_login > .content {
    padding: 0.18em 2.2em;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.button.loading .content {
    visibility: hidden;
}

.button.squared .content,
.button.round .content {
    width: 2.5em;
    height: 2.5em;
    padding: 0.43em;
}

.button.size_tiny .content {
    padding: 0.2em 1.5em;
}

.button.size_micro .content {
    padding: 0.2em 1em;
}

.button.narrow .content {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
}

.button.narrowish .content {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
}

.button.aligned_left,
.button.aligned_right {
    padding-left: 0;
    padding-right: 0;
}

.button.aligned_left .content {
    text-align: left;
    margin-left: 0;
    padding: 0;
}

.button.aligned_right .content {
    text-align: right;
    padding: 0;
    margin-right: 0;
}

.button.leftButton {
    --button-border-radius: 24px 0 0 24px;
    --button-border-radius-inner: var(--button-border-radius);
}

.button.rightButton {
    --button-border-radius: 0 24px 24px 0;
    --button-border-radius-inner: var(--button-border-radius);
}

.button.round.size_micro .content,
.button.squared.size_micro .content {
    padding: 0;
    width: 1.1em;
    height: 1.1em;
}

.button.round.size_tiny .content,
.button.squared.size_tiny .content {
    padding: 0.1em 0;
    width: 1.3em;
    height: 1.3em;
}

.button:not(.theme_link):active {
    transform: scale(0.99);
}

.button:not(.theme_link):active .content {
    box-shadow:
        inset 0 0 1px 1px rgba(0, 0, 0, 0.5),
        inset 0 0 10px 10px rgba(255, 255, 255, 0.15);
}

.spinnerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--button-inner-border);
    border-radius: var(--button-border-radius-inner);
}

.button.theme_dark {
    background-color: #000;
    color: var(--button-outline-color);
    border: 1px solid var(--button-outline-color);
}

.button.theme_primary {
    background-image: var(--gradient-green-v2);
    color: var(--color-text-green-v2);
    text-align: center;
}

.button.theme_primary:hover {
    background-image: var(--gradient-lit-green-v2);
}

.button.theme_secondary {
    --button-outline-color: #48b5de;

    background-image: var(--gradient-blue-v2);
    color: var(--color-text-blue-v2);
}

.button.theme_premium {
    --button-outline-color: #f4b805;

    background-image: var(--gradient-orange-v2);
    color: #3d2500;
}

.button.theme_premium:hover {
    --button-outline-color: #ffc721;

    background-image: linear-gradient(180deg, #fed245 0%, #fba725 100%);
    transition: background-image 0.5s linear;
}

.button.theme_premium.glow {
    box-shadow: 0 0 9px 3px #f89118;
}

.button.theme_exclusive {
    --button-outline-color: #ed69ec;

    background-image: var(--gradient-purple-v2);
    color: #360d39;
}

.button.theme_login {
    --button-outline-color: white;

    background-image: linear-gradient(180deg, #fbfbfd 0%, #c1c9da 100%);
    filter: drop-shadow(2.3px 2.3px 0 rgba(0, 0, 0, 0.28));
    color: #1b2c4f;
}

.button.theme_login:hover {
    background: linear-gradient(180deg, #fbfbfd 0%, #eef3fe 100%);
}

.button.theme_login.button.disabled {
    background: linear-gradient(180deg, #5d7883 0%, #475b62 100%);
}

.button.theme_exclusive:hover {
    --button-outline-color: #ed69ec;

    background-image: linear-gradient(180deg, #ff7ffe 0%, #df4af0 100%);
}

.button.theme_creator {
    background-image: linear-gradient(150deg, #c08fff 0%, #5cebff 100%);
    outline: 2px solid #5cebff;
    color: #212121;
}

.button.theme_minor {
    background-color: #ffffff;
    border: 1px solid #666;
}

.button.theme_link {
    --button-inner-border: none;

    background-image: unset;
    color: var(--color-text-link-green-v2);
    border: none;
    text-shadow: 1px 1px 0.3px rgba(19, 27, 31, 0.79);
    text-transform: none;
}

.button.theme_link.disabled {
    pointer-events: none;
    color: var(--color-input-text-border);
    background-image: unset;
}

.button.size_micro {
    font-size: 14px;
}

.button.size_tiny {
    font-size: 15px;
}

.button.size_small {
    font-size: 13px;
}

.button.size_medium {
    font-size: 16px;
}

.button.fullWidth {
    display: block;
    width: 100%;
    text-align: center;
}

.button.disabled {
    --button-outline-color: #4a6068;

    pointer-events: none;
    color: #1c2c32;
    background-image: var(--gradient-gray-v2);
}

.button.loading {
    pointer-events: none;
}

.button.shadow {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}

.button.oneLine {
    white-space: nowrap;
}

.button.withShine {
    animation: grow 3.5s infinite 3s;
}

.button .shine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--button-border-radius-inner);

    /* Make border-radius work on Safari */
    isolation: isolate;
}

.button .shine::after {
    content: "";
    top: 0;
    left: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 3.5s infinite 3s;
    pointer-events: none;
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(125, 185, 232, 0) 70%
    );
}

.button.squared {
    --button-border-radius: 0.6em;
    --button-border-radius-inner: 0.5em;
}

.button.round {
    --button-border-radius: 2em;
    --button-border-radius-inner: 2em;
}

@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(300%);
    }
}

@keyframes grow {
    13% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.02);
    }

    37% {
        transform: scale(1);
    }
}
